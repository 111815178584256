<script>
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import {TicketStatus, TicketSection, TicketCategory, TicketPriority} from "@/enums";
import { VclCode } from 'vue-content-loading';

import {with_acrf_token} from "@/methods";


export default {

  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId'
    ]),
    rows() {
      return this.tableData.length;
    },
  },
  components: {
    VclCode
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/support/tickets/all', {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.tableData = data.tickets;
            this.totalRows = this.tableData.length;
            this.ready = true;
          })
          .catch(error => {
            this.handleError(error);
          });
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      TicketStatus:TicketStatus,
      TicketSection:TicketSection,
      TicketCategory:TicketCategory,
      TicketPriority:TicketPriority,
      ready: false,
      error: false,
      data: null,
      // Datatables
      filter: null,
      totalRows: 0,
      currentPage: 1,
      filterOn: [],
      sortBy: "updated_at",
      sortDesc: true,
      fields: [
        {
          key: "ids",
          sortable: true,
          label: this.$t('support.view.list.headers.id')
        },
        {
          key: "updated_at",
          sortable: true,
          label: this.$t('support.view.list.headers.updated_at')
        },
        {
          key: "created_at",
          sortable: true,
          label: this.$t('support.view.list.headers.created_at')
        },
        {
          key: "status",
          sortable: true,
          label: this.$t('support.view.list.headers.status')
        },
        {
          key: "category",
          sortable: true,
          label: 'Category'
        },
        {
          key: "section",
          sortable: true,
          label: 'Section'
        },
        {
          key: "id",
          sortable: false,
          searchable: false,
          label: ''
        }
      ],
      tableData: []
    }
  }
};
</script>

<template>
  <div class="col-lg-8 col-sm-12">
    <!-- Display -->
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{$t('support.view.list.title')}}
              </h4>
              <hr>
              <div class="row mt-4 justify-content-end">
                <!-- Search -->
                <div class="col-sm-12 col-md-6 justify-content-end">
                  <div id="tickets-table_filter" class="dataTables_filter float-right">
                    <label class="d-inline-flex align-items-center ">
                      Search:
                      <b-form-input v-model="filter" type="search" placeholder="Search..." class="mw-100 form-control form-control-sm ml-2"></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table :items="tableData" :fields="fields" responsive="sm" :per-page="10" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                  <template #cell(ids)="data">
                    <router-link :to="'/support/'+data.item.id">
                      <b>#{{ data.item.id_human }}</b>
                    </router-link>
                  </template>

                  <template #cell(created_at)="data">
                    {{ $d(parseDate(data.value), 'datetime') }}
                  </template>
                  <template #cell(updated_at)="data">
                    {{ $d(parseDate(data.value), 'datetime') }}
                  </template>

                  <template #cell(status)="data">
                    <template v-if="data.value === TicketStatus.CLOSED">
                      <span class="badge badge-light text-dark">
                        Closed
                      </span>
                    </template>
                    <template v-else-if="data.value === TicketStatus.WAITING_FOR_USER">
                      <span class="badge badge-success text-light">
                        Waiting for user
                      </span>
                    </template>
                    <template v-else-if="data.value === TicketStatus.WAITING_FOR_SUPPORT">
                      <span class="badge badge-info">
                        Waiting for support
                      </span>
                    </template>
                    <template v-else-if="data.value === TicketStatus.WAITING_FOR_TRIAGE">
                      <span class="badge badge-warning text-light">
                        Waiting for triage
                      </span>
                    </template>
                  </template>

                  <template #cell(section)="data">
                    <template v-if="data.value === TicketSection.BILLING">
                      <span class="badge badge-success text-black">
                        Billing
                      </span>
                    </template>
                    <template v-else-if="data.value === TicketSection.OTHER">
                      <span class="badge badge-info text-black">
                        Other
                      </span>
                    </template>
                    <template v-else-if="data.value === TicketSection.TECHNICAL">
                      <span class="badge badge-light">
                        Technical
                      </span>
                    </template>
                  </template>

                  <template #cell(category)="data">
                    <template v-if="data.value === TicketCategory.ACCOUNT">
                      Account
                    </template>
                    <template v-else-if="data.value === TicketCategory.API">
                      API
                    </template>
                    <template v-else-if="data.value === TicketCategory.BILLING">
                      Billing
                    </template>
                    <template v-else-if="data.value === TicketCategory.GENERIC">
                      Generic
                    </template>
                    <template v-else-if="data.value === TicketCategory.ISSUE">
                      Issue
                    </template>
                    <template v-else-if="data.value === TicketCategory.PRODUCT">
                      Product
                    </template>
                    <template v-else-if="data.value === TicketCategory.TECHNICAL">
                      Technical
                    </template>
                  </template>

                  <template #cell(id)="data">
                    <router-link :to="'/support/'+data.value">
                      <button class="btn btn-rounded btn-sm btn-primary">
                        <i class="fas fa-envelope-open-text"></i>
                        {{$t('support.view.list.open')}}
                      </button>
                    </router-link>
                  </template>

                  <template #cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="10"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Error -->
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Loading -->
    <template v-else>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                {{$t('support.view.list.title')}}
              </h4>
              <hr>
              <vcl-code :height="110"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>